<template>
  <q-btn-dropdown style="padding: 0" flat no-caps :label="showLang()">
    <q-list>
      <q-item clickable v-close-popup @click="chooseLang('en')">
        <q-item-section>
          <q-item-label>English</q-item-label>
        </q-item-section>
      </q-item>

      <q-item clickable v-close-popup @click="chooseLang('zh')">
        <q-item-section>
          <q-item-label>简体中文</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>

<script>
export default {
  data() {
    return {
      httpProcess: false,
    };
  },
  methods: {
    showLang() {
      switch (this.$i18n.locale) {
        case "en":
          return "English";
        case "zh":
          return "简体中文";
        default:
          return "---";
      }
    },
    chooseLang(v) {
      var self = this;
      if (self.httpProcess) return;
      self.httpProcess = true;

      self
        .$axios({
          method: "post",
          url: "/api/set/lang",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          params: { lang: v },
        })
        .then(() => {
          self.httpProcess = false;
          // 本地保存 <=> 当前设置
          self.$i18n.locale = v;
          window.sessionStorage["lang"] = v;
          self.$parent.clearMsg && self.$parent.clearMsg();
          document.title = this.$t("common.item_32");
        })
        .catch(function (error) {
          self.httpProcess = false;
          var msg;
          switch (error.response.data.code) {
            case 401:
              msg = self.$t("common.item_01");
              break;
            default:
              msg = self.$t("common.item_02");
          }
          self.$q.dialog({
            message: msg,
            ok: self.$t("common.item_03"),
          });
        });
    },
  },
};
</script>
