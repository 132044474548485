<template>
  <div style="padding: 1rem">
    <div
      style="
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
      "
    >
      <img
        style="width: 10px; height: auto"
        src="../assets/common-01.png"
        @click="goBack"
      /><SetLang v-if="!kind" />
    </div>

    <div style="position: relative; height: 50vh; overflow: hidden">
      <img
        style="
          position: absolute;
          width: 100%;
          height: auto;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
        src="../assets/common-05.png"
      />
    </div>

    <p style="margin: 0.5rem 0; font-size: 1.1rem; font-weight: bold">
      {{ $t("common.item_18") }}
    </p>

    <div>
      <p style="margin: 0 1rem 0.5rem 1rem; color: red" v-if="imeiMsg">
        {{ imeiMsg }}
      </p>
      <div style="position: relative">
        <input
          style="
            padding: 0.8rem 80px 0.8rem 1rem;
            width: 100%;
            color: #898989;
            background-color: #f6f7fa;
            border-radius: 25px;
            outline: none;
            border: none;
          "
          :placeholder="$t('common.item_19')"
          v-model="imei"
          @focus="
            () => {
              imeiMsg = '';
            }
          "
        />
        <q-btn
          style="
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%);
            background-color: #387bf8;
            border-radius: 25px;
            padding: 0.8rem 1.5rem;
          "
          @click="goScan"
        >
          <img
            style="width: 20px; height: auto"
            src="../assets/common-06.png"
          />
        </q-btn>
      </div>
    </div>

    <div style="padding: 2rem 0 0 0" v-if="checkCode">
      <p style="margin: 0 0 0.5rem 0; font-size: 1rem; font-weight: bold">
        {{ $t("common.item_34") }}
      </p>
      <p style="margin: 0 1rem 0.5rem 1rem; color: red" v-if="codeMsg">
        {{ codeMsg }}
      </p>
      <div
        style="
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          justify-content: space-between;
        "
      >
        <input
          style="
            padding: 0.8rem 1rem;
            width: 65%;
            color: #898989;
            background-color: #f6f7fa;
            border-radius: 25px;
            outline: none;
            border: none;
          "
          :placeholder="$t('common.item_35')"
          v-model="code"
          @focus="
            () => {
              codeMsg = '';
            }
          "
        />
        <VCode v-model:value="validCode"></VCode>
      </div>
    </div>

    <q-btn
      style="
        margin: 2rem 0 0 0;
        background-color: #387bf8;
        padding: 0.7rem 0;
        font-size: 1rem;
        color: #fff;
      "
      class="full-width"
      rounded
      no-caps
      :label="$t('common.item_21')"
      @click="submit"
    />
  </div>
</template>

<script>
import SetLang from "@/components/lang.vue";
import VCode from "@/components/vcode.vue";
export default {
  components: {
    SetLang,
    VCode,
  },
  data() {
    return {
      httpProcess: false,
      imeiMsg: "",
      imei: "",

      checkCode: false,
      validCode: "",
      codeMsg: "",
      code: "",

      kind: "",
    };
  },
  created() {
    this.showCode();
    this.kind = this.getUrlArg("code");
    var scan = window.sessionStorage["scan"];
    if (scan) this.imei = scan;
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goScan() {
      this.$router.push({ path: "/scan" });
    },
    clearMsg() {
      this.imeiMsg = "";
      this.codeMsg = "";
    },
    showCode() {
      var code = parseInt(window.sessionStorage["code"]);
      if (code && code >= 3) {
        this.checkCode = true;
      }
    },
    getUrlArg(str) {
      if (!str) return;
      var arr = document.location.toString().split(str + "=");
      if (arr.length == 2 && arr[1].length > 0) {
        var index = arr[1].indexOf("&");
        if (index > 0) {
          str = arr[1].substring(0, index);
        } else if (index == -1) {
          str = arr[1];
        } else {
          str = "";
        }
      } else {
        str = "";
      }
      return str;
    },
    submit() {
      var self = this;
      self.$buryPoint("user_query_dev", {});
      if (!self.imei) {
        self.imeiMsg = self.$t("common.item_20");
        return;
      }
      if (self.checkCode && !self.code) {
        self.codeMsg = self.$t("common.item_36");
        return;
      }
      if (self.checkCode && self.code != self.validCode) {
        self.codeMsg = self.$t("common.item_37");
        return;
      }

      if (self.httpProcess) return;
      self.httpProcess = true;
      self
        .$axios({
          method: "post",
          url: "/api/set/imei",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          params: { imei: self.imei, kind: self.kind },
        })
        .then(() => {
          window.sessionStorage["code"] = 0;
          self.$router.push({ path: "/", query: { mark: "imei" } });
        })
        .catch(function (error) {
          var code = parseInt(window.sessionStorage["code"]);
          if (code) {
            window.sessionStorage["code"] = code + 1;
          } else {
            window.sessionStorage["code"] = 1;
          }
          self.showCode();
          self.httpProcess = false;
          var msg;
          switch (error.response.data.code) {
            case 401:
              msg = self.$t("common.item_01");
              break;
            default:
              msg = self.$t("common.item_02");
          }
          self.$q.dialog({
            message: msg,
            ok: self.$t("common.item_03"),
          });
        });
    },
  },
};
</script>
